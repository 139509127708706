
import * as ipxRuntime$G7FMnRyE8s from '/runner/_work/checkout-web/checkout-web/node_modules/.pnpm/@nuxt+image@1.0.0-rc.3_rollup@4.12.0/node_modules/@nuxt/image/dist/runtime/providers/ipx'

export const imageOptions = {
  "screens": {
    "xs": 320,
    "sm": 640,
    "md": 768,
    "lg": 1024,
    "xl": 1280,
    "xxl": 1536,
    "2xl": 1536
  },
  "presets": {
    "webp": {
      "modifiers": {
        "format": "webp",
        "quality": 90
      }
    }
  },
  "provider": "ipx",
  "domains": [
    "localhost:3010",
    "127.0.0.1:3000"
  ],
  "alias": {},
  "densities": [
    1,
    2
  ],
  "format": [
    "webp",
    "webp"
  ]
}

imageOptions.providers = {
  ['ipx']: { provider: ipxRuntime$G7FMnRyE8s, defaults: undefined }
}
        